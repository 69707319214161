import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { H4, H5, ButtonPurp } from "./UI"
import Icon from "@mdi/react"
import { mdiAccountGroup } from "@mdi/js"
import AwesomeSlider from "react-awesome-slider"
import "react-awesome-slider/dist/styles.css"

const query = graphql`
  query {
    allContentfulVehicle {
      edges {
        node {
          name
          alternateName
          passengers
          features
          photos {
            id
            file {
              url
            }
          }
        }
      }
    }
  }
`

const VehicleCard = props => {
  const queryData = useStaticQuery(query)

  const dataFilter = queryData.allContentfulVehicle.edges.filter(function (e) {
    return e.node.name === props.name
  })

  const data = dataFilter[0].node

  return (
    <div className="shadow-box h-full flex flex-col bg-white">
      <AwesomeSlider bullets={true}>
        {data.photos.map(photo => {
          return <div data-src={photo.file.url} key={photo.id} />
        })}
      </AwesomeSlider>
      <div className="px-3 py-4 flex flex-col flex-auto text-center">
        {props.button ? (
          <ButtonPurp shadow className="w-fit mx-auto -mt-8 z-10 mb-4">
            Request a Quote
          </ButtonPurp>
        ) : null}
        {props.fullName ? (
          <H5 className="mt-2 mb-2">{data.name}</H5>
        ) : (
          <H4 className="mt-2 mb-2">{data.alternateName}</H4>
        )}
        <p className="mb-2">
          <Icon path={mdiAccountGroup} size={1} className="inline pb-1 mr-1" />
          {data.passengers} Passengers
        </p>
        {data.features.map(f => {
          return (
            <p className="text-sm mb-2" key={f}>
              {f}
            </p>
          )
        })}
      </div>
    </div>
  )
}

VehicleCard.defaultProps = {
  button: false,
  fullName: false,
}

export default VehicleCard
