import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const PageHeader = props => {
  const query = useStaticQuery(graphql`
    query {
      allContentfulAsset {
        edges {
          node {
            title
            file {
              url
            }
          }
        }
      }
    }
  `)
  const data = query.allContentfulAsset.edges

  const imageFilter = data.filter(function (e) {
    return e.node.title === `${props.pageTitle} Page Header`
  })

  const backgroundImage = imageFilter[0].node.file.url

  return (
    <div
      className="mx-auto h-auto bg-center relative bg-cover bg-no-repeat"
      style={{
        backgroundImage: `linear-gradient(110deg,rgba(11,123,218,.6) 0,rgba(255,0,200,.6) 100%), url(${backgroundImage})`,
      }}
    >
      {props.children}
      <div
        className="h-24 bottom-0 block w-full bg-cover bg-top"
        style={{ backgroundImage: `url(${props.divider})` }}
      ></div>
    </div>
  )
}

PageHeader.defaultProps = {
  backgroundImage: "/images/default-page-header-background.jpg",
  divider: "/images/dividers/divider.svg",
}

export default PageHeader
