import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import PageHeader from "../components/PageHeader"
import { H1, H3, Container, Row, Col } from "../components/UI"
import VehicleCard from "../components/VehicleCard"
import SectionTitle from "../components/SectionTitle"
import Icon from "@mdi/react"
import { mdiHeart } from "@mdi/js"
import ToggleBox from "../components/ToggleBox"
import Fade from "react-reveal/Fade"
import Zoom from "react-reveal/Zoom"
import ReviewCards from "../components/ReviewCards"

export const query = graphql`
  query($slug: String!) {
    contentfulWeddingPage(slug: { eq: $slug }) {
      slug
      coupleName
      weddingDate(formatString: "MMMM DD, YYYY")
      priceExpirationDate(formatString: "MMMM DD, YYYY")
      priceDescription {
        priceDescription
      }
      totalPrice
      weddingCoordinator {
        name
        email
        phoneNumber
        photo {
          file {
            url
          }
        }
      }
      vehicles {
        name
        photos {
          file {
            url
          }
        }
        features
      }
    }
  }
`

const WeddingPage = props => {
  const data = props.data.contentfulWeddingPage

  console.log(data)
  return (
    <Layout>
      <PageHeader
        pageTitle="Wedding Transportation"
        divider="/images/dividers/bubbles.svg"
      >
        <section>
          <Container>
            <Row className="items-center pt-8 pb-20 sm:py-24">
              <Col className="w-full lg:w-3/5 mt-4 lg:mt-0 text-center lg:text-left">
                <H1>{data.coupleName}</H1>
                <p className="text-white font-heading text-4xl">
                  <Icon
                    path={mdiHeart}
                    size={2}
                    color="#fff"
                    className="block lg:inline pb-1 text-center mx-auto lg:mr-3"
                  />
                  {data.weddingDate}{" "}
                </p>
                <p className="text-white text-sm sm:text-lg text-shadow">
                  {data.coupleName}, thank you for considering Kevin Smith
                  Transportation Group to provide luxury wedding transportation
                  on your special day. Below you will find pricing for the
                  services you inquired about, as well as an overview of the
                  vehicles that you requested.
                </p>
              </Col>
              <Col className="w-full lg:w-2/5 mt-4 lg:mt-0">
                <div className="bg-white w-full py-4 px-3 text-center rounded shadow-md">
                  <H3 className="mb-4">Your Wedding Coordinator</H3>
                  <img
                    src={data.weddingCoordinator.photo.file.url}
                    alt={data.weddingCoordinator.name}
                    className="rounded-full h-40 w-40 mx-auto mb-3"
                  ></img>
                  <p className="text-2xl text-dark">
                    {data.weddingCoordinator.name}
                  </p>
                  <a href={`tel:${data.weddingCoordinator.phoneNumber}`}>
                    <p className="text-xl">
                      {data.weddingCoordinator.phoneNumber}
                    </p>
                  </a>
                  <a
                    href={`to:${data.weddingCoordinator.email}`}
                    className="text-purp-100"
                  >
                    <p className="text-xl">{data.weddingCoordinator.email}</p>
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </PageHeader>
      <section className="py-20">
        <Container>
          <SectionTitle
            title="Your Wedding Vehicles"
            text="These are the vehicles you requested information about."
          />
          <Row className="pt-12 justify-center">
            {data.vehicles.map(vehicle => {
              return (
                <Col className="w-full sm:w-1/2 lg:w-1/3 mb-6 lg:mb-0">
                  <VehicleCard name={vehicle.name} fullName />
                </Col>
              )
            })}
          </Row>
        </Container>
      </section>
      <section className="py-20 bg-subtle">
        <Container>
          <SectionTitle
            title="Pricing"
            text="All prices show below reflect all standard fees and gratuity. There are NO hidden fees associated with our services."
          />
          <Row className="pt-12 justify-center">
            <div className="bg-white h-auto w-full sm:w-3/5">
              <div className="shadow-md text-center relative pt-32 pb-16 sm:pt-24 sm:pb-12 md:pt-32 md:pb-16 lg:pt-40 lg:pb-20">
                <img
                  src="/images/weddings/flowers-top.svg"
                  alt="flowers"
                  className="absolute top-0"
                ></img>
                <div className="px-20 sm:px-16 md:px-24 lg:px-32 z-20">
                  <p className="font-heading text-4xl xs:text-5xl md:text-6xl">
                    {data.totalPrice}
                  </p>
                  <p>{data.priceDescription.priceDescription}</p>
                  <p className="mt-8 text-cardGray text-sm">
                    Pricing in valid until {data.priceExpirationDate}
                  </p>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
      <section className="py-20">
        <Container>
          <Zoom>
            <SectionTitle
              title="FAQ's"
              text="Do you have questions before you book? We’ll try to answer as many as we can for you."
            />
          </Zoom>
          <Row className="pt-12">
            <Col className="w-full mb-6 lg:mb-6">
              <Fade left>
                <ToggleBox
                  question="How does your guests shuttle service work?"
                  answer="The way we designed shuttle services for our wedding clients is by breaking up our 4-hour minimum booking time into two, 2-hour blocks – two hours before and two hours after the event. We will make as many trips in each 2-hour block between the hotel and venue to accommodate all of your guests."
                />
              </Fade>
            </Col>
            <Col className="w-full mb-6 lg:mb-6">
              <Fade right>
                <ToggleBox
                  question="Are you able to help me build out an itinerary?"
                  answer="Of course! Our wedding coordinators have lots of experience with most hotels, venues, and churches in the Philadelphia area to help you plan out and schedule the perfect itinerary for your special day."
                />
              </Fade>
            </Col>
            <Col className="w-full mb-6 lg:mb-6">
              <Fade left>
                <ToggleBox
                  question="What are your payment terms?"
                  answer="To secure your wedding transportation, we require a 50% deposit the day of booking. Your final payment will then be due 30 days before your wedding date."
                />
              </Fade>
            </Col>
            <Col className="w-full mb-6 lg:mb-6">
              <Fade right>
                <ToggleBox
                  question="Are there any other 'hidden fees' that I will have to pay?"
                  answer="Nope! Every price that you receive from us will include all fees as well as driver gratuity built in."
                />
              </Fade>
            </Col>
            <Col className="w-full mb-6 lg:mb-6">
              <Fade left>
                <ToggleBox
                  question="Do you offer any discounts?"
                  answer="We do! We offer discounts when booking multiple vehicles such as a party bus for the wedding party and a shuttle for your guests. We also partner with many hotels and venues in the area to bring you discounts when booking there."
                />
              </Fade>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-20 bg-subtle">
        <Container>
          <Zoom>
            <SectionTitle
              title="How Do We Compare"
              text="See how we compare to other Philadelphia based limo rental companies as well as national limo companies"
            />
          </Zoom>
          <Zoom>
            <Row className="pt-12 items-center">
              <ReviewCards />
            </Row>
          </Zoom>
        </Container>
      </section>
    </Layout>
  )
}

export default WeddingPage
