import React from "react"
import { H4 } from "./UI"
import Icon from "@mdi/react"
import { mdiStar } from "@mdi/js"

const ReviewCards = () => {
  return (
    <React.Fragment>
      <div className="w-full md:w-1/3 mb-8 lg:mb-0">
        <div className="shadow-box h-full flex flex-col bg-white">
          <div className="px-3 py-4 flex flex-col flex-auto text-center">
            <img
              src="/images/logos/philly-limo-company-logo.svg"
              alt="philly limo company logo"
              className="w-32 w-32 mx-auto mb-8"
            ></img>
            <H4 className="mb-2">Top 20 Philadelphia Limousine Companies</H4>
            <p className="text-cardGray text-xl">Average Rating</p>
            <p className="text-3xl">3.7/5</p>
            <div className="flex justify-center py-2">
              <Icon path={mdiStar} size={1.6} color="#FEA000" />
              <Icon path={mdiStar} size={1.6} color="#FEA000" />
              <Icon path={mdiStar} size={1.6} color="#FEA000" />
              <Icon path={mdiStar} size={1.6} color="#D9D9D9" />
              <Icon path={mdiStar} size={1.6} color="#D9D9D9" />
            </div>
            <p className="text-cardGray text-xl">Average Number of Reviews</p>
            <p className="text-3xl">27</p>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/3 mb-8 lg:mb-0 z-10">
        <div className="shadow-box h-full flex flex-col bg-white py-0 md:py-12">
          <div className="px-3 py-4 flex flex-col flex-auto text-center">
            <img
              src="/images/logos/kstg-logo-square.svg"
              alt="philly limo company logo"
              className="w-40 w-40 mx-auto"
            ></img>
            <H4 className="mb-2">Kevin Smith Transportation Group</H4>
            <p className="text-cardGray text-xl">Average Rating</p>
            <p className="text-3xl">4.9/5</p>
            <div className="flex justify-center py-2">
              <Icon path={mdiStar} size={1.6} color="#FEA000" />
              <Icon path={mdiStar} size={1.6} color="#FEA000" />
              <Icon path={mdiStar} size={1.6} color="#FEA000" />
              <Icon path={mdiStar} size={1.6} color="#FEA000" />
              <Icon path={mdiStar} size={1.6} color="#FEA000" />
            </div>
            <p className="text-cardGray text-xl">Number of Reviews</p>
            <p className="text-3xl">551</p>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/3 mb-8 lg:mb-0">
        <div className="shadow-box h-full flex flex-col bg-white">
          <div className="px-3 py-4 flex flex-col flex-auto text-center">
            <img
              src="/images/logos/national-limo-company-logo.svg"
              alt="philly limo company logo"
              className="w-32 w-32 mx-auto mb-8"
            ></img>
            <H4 className="mb-2">Top 20 National Limousine Companies</H4>
            <p className="text-cardGray text-xl">Average Rating</p>
            <p className="text-3xl">3.9/5</p>
            <div className="flex justify-center py-2">
              <Icon path={mdiStar} size={1.6} color="#FEA000" />
              <Icon path={mdiStar} size={1.6} color="#FEA000" />
              <Icon path={mdiStar} size={1.6} color="#FEA000" />
              <Icon path={mdiStar} size={1.6} color="#FEA000" />
              <Icon path={mdiStar} size={1.6} color="#D9D9D9" />
            </div>
            <p className="text-cardGray text-xl">Average Number of Reviews</p>
            <p className="text-3xl">37</p>
          </div>
        </div>
      </div>
      <p className="text-xs max-w-screen-md text-cardGray text-center pt-8 mx-auto">
        Review data taken from Google as of 10/04/19. Top 20 Philadelphia
        Limousine Companies based off of review numbers. Top 20 National
        Limousine Companies based off of LCT 2016 Largest Fleets.
      </p>
    </React.Fragment>
  )
}

export default ReviewCards
