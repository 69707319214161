import React, { useState } from "react"
import { Collapse } from "react-collapse"
import { H5 } from "./UI"

const ToggleBox = props => {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <React.Fragment>
      <div className="border-subtle border shadow py-4 px-4">
        <div className="flex items-center justify-between">
          <H5>{props.question}</H5>
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="h-8 w-8 bg-subtle text-lg rounded-full hover:bg-gray-300"
          >
            {isOpen ? "-" : "+"}
          </button>
        </div>
        <Collapse
          isOpened={isOpen}
          theme={{
            collapse: "collapse transition-all duration-200 ease-in-out",
            content: "content",
          }}
        >
          <p className="mt-3 text-gray-500">{props.answer}</p>
        </Collapse>
      </div>
    </React.Fragment>
  )
}

export default ToggleBox
